.container {
  position: relative;
  height: 50px;
}

.dropdown {
  display: block;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-surface-glass-dark-10);
  border-radius: var(--radius-medium);
  background-color: var(--color-surface-solid-light-lighter);
  width: 100%;
  max-height: 50px;
  overflow: hidden;
}

.container--expanded {
  max-height: 280px;
  overflow-y: scroll;
}

.label {
  position: relative;
  cursor: pointer;
  padding-inline-start: 16px; /* RTL support */
  padding-inline-end: 32px; /* RTL support */
  padding-block: 16px;
  height: 50px;
  color: var(--color-text-subtle);
  font-size: var(--font-size-18px);
  line-height: 1; /* easier center positioning */
}

.container--expanded .label {
  color: var(--color-text-subtle);
  font-weight: 700;
  font-size: var(--font-size-16px);
  text-transform: uppercase;
}

.label-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  transition: transform 0.2s ease;
  margin-block: auto;
  inset-inline-end: 11px; /* RTL support */
  width: 23px;
  height: 23px;
  color: var(--color-primary);
}

.container--expanded .label-icon {
  transform: rotateX(180deg);
}

.option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  height: 50px;
  color: var(--color-text);
  font-weight: 700;
  font-size: var(--font-size-18px);
  line-height: 1;
  text-decoration: none;
}

.option:hover,
.option:focus {
  outline: none;
  color: var(--color-primary);
}
