.container {
  margin-bottom: 28px;
}

@media (width >= 650px) {
  .container {
    margin-bottom: 140px;
  }
}

.content {
  position: relative;
  background-image: var(--contact-form-stage-background-image);
  background-position: right;
  background-repeat: no-repeat;
  color: var(--color-bg-shade);
}

@media (width >= 650px) {
  .content {
    height: 640px;
  }
}

@media (width >= 1000px) {
  .content {
    background-size: cover;
    height: 725px;
  }
}

.layout-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
  padding-inline: var(--spacing-sides);
  padding-bottom: 33px;
  height: 520px;
}

@media (width >= 650px) {
  .layout-wrapper {
    padding-bottom: 103px;
    height: 100%;
  }
}

.layout-wrapper-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start; /* Default is stretch which makes any buttons go block/full width */
  margin-inline: auto;
  width: 100%;
  max-width: var(--max-width-base);
}

.kicker-wrapper {
  display: block;
  margin-bottom: 16px;
}

@media (width >= 1000px) {
  .kicker-wrapper {
    margin-bottom: 18px;
  }
}

.title {
  margin-bottom: 24px;
  max-width: 550px;
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-54px);
  line-height: 1.15;
  font-family: var(--font-family-heading);
}

@media (width >= 650px) {
  .title {
    margin-bottom: 25px;
    max-width: 550px;
    font-size: var(--font-size-76px);
    line-height: 1;
  }
}

@media (width >= 1000px) {
  .title {
    margin-bottom: 25px;
    max-width: 768px;
    font-size: var(--font-size-88px);
    line-height: 1.09;
  }
}

.description {
  margin-bottom: 24px;
  font-size: var(--font-size-18px);
  line-height: 1.33;
}

@media (width >= 650px) {
  .description {
    opacity: 0.8;
  }
}

.description:last-child {
  margin-bottom: 0;
}

@media (width >= 650px) {
  .description {
    display: block;
    margin-bottom: 38px;
    max-width: 510px;
    font-size: var(--font-size-24px);
    line-height: 1.5;
  }
}

.image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 520px;
}

@media (width >= 650px) {
  .image {
    height: 100%;
  }
}

.image::after {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 15, 30, 0.6) 10%,
    rgba(0, 15, 30, 0) 80%
  );
  width: 100%;
  height: 100%;
  pointer-events: none;
  content: "";
}

@media (width >= 1000px) {
  .image::after {
    background-image: linear-gradient(
      to left,
      rgba(0, 15, 30, 0),
      rgba(0, 15, 30, 0.38) 44%,
      rgba(0, 15, 30, 0.67)
    );
  }
}

.image-view {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.image-container {
  position: relative;
  top: -7px;
  left: -7px;
  transition: filter 300ms linear;
  width: calc(100% + 14px);
  height: calc(100% + 14px);
}

.image-content {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-wrapper {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.video-wrapper::after {
  position: absolute;
  inset: 0;
  background-image: linear-gradient(
    to top,
    var(--color-surface-solid-dark-darker) 0,
    rgba(0, 0, 0, 0)
  );
  background-size: cover;
  background-repeat: no-repeat;
  content: "";
}

.video {
  position: absolute;
  margin: auto;
  inset: -9999px;
  background-color: var(--color-surface-solid-dark-darker);
  min-width: 100%;
  min-height: 100%;
}

.cta-button {
  --cosmos-button-min-width: var(--button-min-width);
}

html[dir="rtl"] .cta-button-icon {
  transform: scaleX(-1); /* Speech-bubble icon flipped for RTL */
}
